<template>
  <div
    id="modal-contract-price"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-add-cage-activity"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <button
        class="uk-modal-close-default"
        type="button"
        uk-close
      />
      <h4>Harga Kontrak Sapronak</h4>
      <p>
        Update terakhir: {{ modalPrice && modalPrice.data ? modalPrice.data.updated_at : '' }}<br>
        <!-- Tanggal berlaku: {{ modalPrice && modalPrice.data ? modalPrice.data.effective_date : '' }} -->
      </p>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-2">
          <div class="uk-grid uk-grid-small ">
            <div class="uk-width-1">
              Harga DOC/ekor
            </div>
            <div class="uk-width-1">
              <label
                class="uk-input"
                disabled
              >
                {{ formatCurrency( modalPrice && modalPrice.data ? modalPrice.data.doc_price : '-') }}
              </label>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2">
          <div class="uk-grid uk-grid-small ">
            <div class="uk-width-1">
              Harga Pakan/kg
            </div>
            <div class="uk-width-1">
              <label
                class="uk-input"
                disabled
              >
                {{ formatCurrency(modalPrice && modalPrice.data ? modalPrice.data.feed_price : '-') }}
              </label>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2">
          <div class="uk-grid uk-grid-small ">
            <div class="uk-width-1">
              Harga OVK/pcs
            </div>
            <div class="uk-width-1">
              <label
                class="uk-input"
                disabled
              >
                {{ formatCurrency( modalPrice && modalPrice.data ? modalPrice.data.ovk_price : '-' ) }}
              </label>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2">
          <div class="uk-grid uk-grid-small ">
            <div class="uk-width-1">
              Tipe Harga
            </div>
            <div class="uk-width-1">
              <label
                class="uk-input"
                disabled
              >
                {{ formatCurrency(modalPrice && modalPrice.data ? numPrice(modalPrice.data.price_type) : '-' ) }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <h4 style="margin-top: 48px">
        Harga Kontrak Jual Ayam Hidup
      </h4>
      <p>Update terakhir: {{ formatDate(getLatestUpdatedAt(modalPrice.data.contract_price_chicken)) }}</p>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-3">
          <div class="uk-grid uk-grid-small ">
            <div class="uk-width-1">
              Bobot Rataan (kg)
            </div>
            <div
              v-for="(doc, i) in modalPrice && modalPrice.data ? modalPrice.data.contract_price_chicken : []"
              :key="i"
              class="label-field uk-width-1"
            >
              <label
                class="uk-input"
                disabled
              >
                {{ doc.min }} - {{ doc.max }}
              </label>
            </div>
          </div>
        </div>
        <div class="uk-width-2-3">
          <div class="uk-grid uk-grid-small ">
            <div class="uk-width-1">
              Harga Jual Ayam Hidup/Kg
            </div>
            <div
              v-for="(doc, i) in modalPrice && modalPrice.data ? modalPrice.data.contract_price_chicken : []"
              :key="i"
              class="label-field uk-width-1"
            >
              <label
                class="uk-input"
                disabled
              >
                Rp{{ doc.price }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateUtcParanjeString, currency } from '@/utils/formater'

export default {
  computed : {
    ...mapGetters({
      modalPrice: 'contractPrice/modalPrice'
    })
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    formatCurrency(val) {
      if(val == 'Rp0' || val == '0'){
        val = 'Normal'
        return val
      } else {
        if(typeof val == 'string') {
          val = Number(val)
        }
        return currency(val)
      }
    },
    numPrice(val) {
      return val.replace(/[^\d]/g, '')
    },
    getLatestUpdatedAt(contractPriceData) {
      if (!contractPriceData || !Array.isArray(contractPriceData)) {
        return null
      }
      var latestDate = new Date(0)
      for (var i = 0; i < contractPriceData.length; i++) {
        var updatedAt = new Date(contractPriceData[i].updated_at)
        if (updatedAt > latestDate) {
          latestDate = updatedAt
        }
      }
      return latestDate.toISOString()
    }
  }
}
</script>

<style scoped>
.uk-modal-dialog {
  text-align: start;
}
.uk-modal-close-default {
  color: #1F2E28;
  background: rgba(64, 64, 64, 0.1);
  top: unset;
  right: 30px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
h4 {
  font-weight: 500;
  font-size: 16px;
  color: #1F2E28;
  margin-bottom: 4px;
}
p {
  margin: 0 0 24px;
}
.uk-input {
  background: #F0F5F3;
  border-radius: 6px;
}
.label-field {
  margin: 8px 0;
}
</style>
